import { AxiosError } from 'axios';

type ErrorData = {
  message: string | string[];
};

export const getErrorMessage = (error: unknown): string => {
  if (!(error instanceof AxiosError)) {
    return String(error);
  }

  const { data } = error.response || {};


  if (typeof data === 'string') {
    const { message }: ErrorData = JSON.parse(data);

    return Array.isArray(message) ? message.join(', ') : message;
  }

  if ('message' in data) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return data.message;
  }

  return error.message;
};
