export const QUIZER_ERRORS_BY_KEY = {
  99: 'Неизвестная ошибка',
  101: 'Ошибка чтения данных из тела запроса',
  102: 'Ошибка записи данных',
  103: 'Ошибка при обращении к базе',
  104: 'Анкеты пользователя не найдены',
  105: 'Поле "user_id" не может быть пустым',
  106: 'Поле "to_user_id" не может быть пустым',
  107: 'Поле "anketa_id" не может быть пустым',
  108: 'Поле "id" не может быть пустым',
  109: 'Поле "indicator_id" не может быть пустым',
  110: 'Поле "answer" не может быть пустым',
  111: 'Массив пустой',
  112: 'Ошибка валидации',
  113: 'Массив не может быть пустым',
  114: 'Массив должен быть пустым',
  115: 'Некорректный тип анкеты',
  116: 'Уже в процессе',
  117: 'Ошибка получения анкет',
  118: 'Отсутствует требуемый тип анкеты',
  119: 'Ошибка получения пользователей',
  120: 'Пользователь не активен',
  121: 'Роль пользователя не соответствует',
  122: 'Пользователь отсутствует',
  123: 'Поле "created_at" не может быть пустым',
  124: 'Отчеты не найдены',
  125: 'Поле "org_id" не может быть пустым',
  126: 'Поля "from" и "to" не могут быть пустыми для не пустого поля "created_at"',
  127: 'Поля "from" и "to" не могут быть пустыми для не пустого поля "finished_at"',
  128: 'Ошибка генерации excel файла',
  129: 'Ошибка потоковой загрузки файла',
  130: 'Поля "from" и "to" не могут быть пустыми для не пустого поля "created_at"',
  131: 'Поля "from" и "to" не могут быть пустыми для не пустого поля "finished_at"',
  132: 'Неподдерживаемый запрос',
  133: 'Слишком много одновременных запросов',
  134: 'Токен не найден',
  135: 'Ошибка авторизации',
  136: 'Неверный токен',
  137: 'У пользователя не заполнены обязательные поля',
  138: 'Ошибка чтения параметров из запроса',
  139: 'Дублирование id (workers, coworkers, heads не должны пересекаться по id друг с другом и с "to_user_id")',
};
