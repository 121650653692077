export enum EnvKeys {
  assessorApi = 'REACT_APP_ASSESSOR_API',
  builderAPI = 'REACT_APP_ASSMNT_BUILDERNEXT_API',
  moderationWEB = 'REACT_APP_ASSMNT_MODERATE_WEB',
  builderWEB = 'REACT_APP_ASSMNT_BUILDERNEO_WEB',
  yandexMetrikaId = 'REACT_APP_ASSMNT_YANDEX_METRIKA_ID',
  localToken = 'REACT_APP_LOCAL_TOKEN',
  localS3Token = 'REACT_APP_LOCAL_S3_TOKEN',
  loggerAPI = 'REACT_APP_LOGGER_API',
  loggerAPIKey = 'REACT_APP_LOGGER_API_KEY',
  bucketAPI = 'REACT_APP_ASSMNT_S3_BUCKET_API',
}

export interface IConfigStore {
  builderAPI: string;
  assessorApi: string;
  localToken: string | undefined;
  localS3Token: string | undefined;
  yandexMetrikaId: string | undefined;
  builderWEB: string;
  moderationWEB: string;
  loggerAPI: string | undefined;
  loggerAPIKey: string | undefined;
  bucketAPI: string;
}
