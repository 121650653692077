import { toast } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/notification';
import { HTTPError } from 'ky';


export interface RequestControllerOptions {
  showError?: boolean;
}


interface CallbackFunctionsType<T> {
  successCallback?: (response: T) => void;
  finallyCallback?: () => void;
  errorCallback?: (error: HTTPError | Error) => void;
}

export const requestController = async <T>(
  promiseFunction: () => Promise<T>,
  callbackFunctions: CallbackFunctionsType<T> = {},
  options: RequestControllerOptions = {
    showError: true,
  },
) => {
  const { successCallback, errorCallback, finallyCallback } = callbackFunctions;

  try {
    const data = await promiseFunction();

    if (successCallback) {
      successCallback(data);
    }
  }
  catch (error) {
    const err = error as HTTPError | Error;

    const { message } = err instanceof HTTPError ? await err.response.json() : (err as Error);

    if (errorCallback) {
      errorCallback(err);
    }

    if (options.showError) {
      toast({
        type: 'error',
        text: message,
      });
    }
  }
  finally {
    if (finallyCallback) {
      finallyCallback();
    }
  }
};
