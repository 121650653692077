import { IMultiSelectOption, MultiSelect, Switch } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { Input } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/input';
import { useDebounceFn } from 'ahooks';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { answersStore } from '../../AnswersStore';

// const STATUS_OPTIONS = [
//   {
//     label: 'Новое',
//     value: 'new',
//   },
//   {
//     label: 'Завершено',
//     value: 'finished',
//   },
//   {
//     label: 'Не требует проверки',
//     value: 'not-checked',
//   },
// ];

export const AnswerFilters = observer(() => {
  const { competencyOptions: { data: options, loading }, changeAnswerListParams, changeCompetenciesParams, loadNextCompetenciesPage } = answersStore;

  const { run: handleSearch } = useDebounceFn(
    (value) => {
      changeCompetenciesParams({ title: value, page: 1 });
    },
    {
      wait: 300,
    },
  );

  const handleChangePublishDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    let publishDate = undefined;
    if (e.target.value) {
      const date = dayjs(e.target.value);
      publishDate = {
        day: date.get('date'),
        month: date.get('month') + 1,
        year: date.get('year'),
      };
    }
    changeAnswerListParams({ publish_date: publishDate });
  };

  const handleChangeCompetencies = (values: IMultiSelectOption[]) => {
    changeAnswerListParams({ competency: values?.[0]?.value });
  };

  return (
    <div className="flex justify-between">
      <div className="flex items-center space-x-7">
        <div className="flex flex-col">
          <span className="mb-1 text-slate-400">По дате публикации</span>
          <Input type="date" state="default" name="date" onChange={handleChangePublishDate} />
        </div>
        <div className="flex flex-col">
          <span className="mb-1 text-slate-400">По компетенциям</span>
          <MultiSelect className="shadow" showChips clear placeholder="По компетенциям" options={options} isLoading={loading} handleSearch={handleSearch} onChange={handleChangeCompetencies} infinityScroll={loadNextCompetenciesPage} />
        </div>
        {/* <div className="flex flex-col">
        <span className="mb-1 text-slate-400">По статусу</span>
        <MultiSelect className="shadow" showChips clear placeholder="Статус" options={STATUS_OPTIONS} />
      </div> */}

      </div>
      <div className="flex items-center">
        <Switch onChange={(onlyNew) => changeAnswerListParams({ only_new: onlyNew })} />
        <span className="ml-2 text-slate-400">Только новые</span>
      </div>
    </div>
  );
});
