import { FC, useEffect, useState } from 'react';
import { appTheme } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/theme';
import { observer } from 'mobx-react';
import { BookIcon } from '../icons/book-icon';
import { LightningIcon } from '../icons/lightning';
import { ISidebarProps } from './sidebar.models';
import { useSidebarStyles } from './sidebar.styles';

export const Sidebar: FC<ISidebarProps> = observer(({ sidebarWidth, startResizing }) => {
  const [menuActive, setMenuActive] = useState<boolean>(false);
  const classes = useSidebarStyles({ sidebarWidth, menuActive });

  // useEffect для сброса активности sidebar-a
  useEffect(() => {
    const updateWindowDimensions = (): void => {
      if (appTheme.breakpoints.lg <= window.innerHeight) {
        setMenuActive(false);
      }
    };

    window.addEventListener('resize', updateWindowDimensions);

    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  return (
    <aside className={classes.root}>
      <div className={classes.container}>
        <div className={classes.content}>
          <div className="px-7 py-3 flex items-center cursor-pointer hover:bg-slate-100 transition-all">
            <LightningIcon />
            <span className="ml-3 font-medium">
              Открытый вопрос
            </span>
          </div>
          <div className="px-7 py-3 flex items-center cursor-pointer hover:bg-slate-100 transition-all">
            <BookIcon className="w-4 h-4" />
            <span className="ml-3 font-medium">
            Лабораторные работы
            </span>
          </div>
        </div>
      </div>
      <div className={classes.resizeBlock} onMouseDown={startResizing} />
    </aside>
  );
});
