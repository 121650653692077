import React, { useMemo, useState } from 'react';
import { ISelectOption, Pagination } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { Paper } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/paper';
import { observer } from 'mobx-react';
import { LoaderPage } from '../../components/loader';
import { answersStore } from './AnswersStore';
import { AnswerFilters } from './components/AnswerFilters';
import { AnswerItem } from './components/AnswerItem';

export const ListOfAnswers: React.FC = observer(() => {
  const [visibleAnswer, setVisibleAnswer] = useState('');

  const { answersList: { data, loading, total }, answerListParams, changeAnswerListParams } = answersStore;

  const maxPage = useMemo(() => Math.ceil(total / answerListParams.paginate_data.limit), [total, answerListParams.paginate_data.limit]);

  const handleChangeLimit = (limit: ISelectOption) => {
    if (limit) {
      changeAnswerListParams({ paginate_data: { page: 1, limit } });
    }
  };

  const toggleVisible = (id: string) => setVisibleAnswer((prev) => (prev === id ? '' : id));

  const handleNextPage = () => {
    if (answerListParams.paginate_data.page < maxPage) {
      changeAnswerListParams({ paginate_data: { ...answerListParams.paginate_data, page: answerListParams.paginate_data.page + 1 } });
    }
  };

  const handlePrevPage = () => {
    if (answerListParams.paginate_data.page > 1) {
      changeAnswerListParams({ paginate_data: { ...answerListParams.paginate_data, page: answerListParams.paginate_data.page - 1 } });
    }
  };

  return (
    <Paper className="grow px-10 py-5 space-y-6">
      <div className="flex flex-col space-y-1">
        <h1 className="text-[1.75rem] font-bold">Список ответов</h1>
      </div>
      <AnswerFilters />
      {loading
        ? <LoaderPage />
        : <>
          <div className="flex flex-col gap-y-6 relative">
            {(data || []).map((answer) => (
              <AnswerItem key={answer._id} answer={answer} isActive={answer._id === visibleAnswer} onChangeActive={() => toggleVisible(answer._id)} />
            ))}
          </div> <Pagination total={total} limit={answerListParams.paginate_data.limit} page={answerListParams.paginate_data.page} maxPage={maxPage}
            minPage={1} onLimitChange={handleChangeLimit} onNextClick={handleNextPage} onPrevClick={handlePrevPage} limitsOptions={[10, 15, 20, 25]} />
        </>}

    </Paper>
  );
});
