import { makeAutoObservable, onBecomeObserved, reaction } from 'mobx';
import { approveAnswer, getQuestions } from '../../../api/assessorApi';
import { IAnswerParams, IAnswerResponse, IApproveAnswerParams } from '../../../api/assessorApi/assessorApi.types';
import { ListStoreData, SearchParams } from '../../../api/common.types';
import { getCompetencies } from '../../../api/CompetencyApi/CompetencyApi';
import { requestController } from '../../../shared/requestController';
import { withoutEmptyProperties } from '../../../shared/without-empty-properties';

class AnswersStore {

  public answerListParams:IAnswerParams = {
    paginate_data: { page: 1, limit: 10 },
  };

  public answersList: ListStoreData<IAnswerResponse> = {
    data: [],
    loading: false,
    total: 0,
  };

  public competenciesParams: SearchParams = {
    page: 1,
    limit: 10,
  };

  public competencyOptions: ListStoreData<{ label: string, value: string }> = {
    data: [],
    loading: false,
    total: 0,
  };

  constructor() {
    makeAutoObservable(this);

    onBecomeObserved(this, 'answersList', this.getAnswersList);
    onBecomeObserved(this, 'competencyOptions', this.getCompetencyOptions);

    reaction(() => this.answerListParams, this.getAnswersList);
    reaction(() => this.competenciesParams, this.getCompetencyOptions);
  }

  public getAnswersList = () => {
    this.answersList.loading = true;

    return requestController(() => getQuestions(this.answerListParams), {
      successCallback: (data) => {
        this.answersList.data = data.values[0].items;
        this.answersList.total = data.values[0].count;
      },
      finallyCallback: () => {
        this.answersList.loading = false;
      },
    });
  };

  public changeAnswerListParams = (params: SearchParams) => {
    this.answerListParams = { ...this.answerListParams, ...params };
  };

  public approveAnswer = (data: IApproveAnswerParams) => requestController(() => approveAnswer(data), {
    successCallback: () => {
      this.getAnswersList();
    },
  });

  public loadNextCompetenciesPage = () => {
    if (this.competencyOptions.total > this.competenciesParams.page * this.competenciesParams.limit) {
      this.changeCompetenciesParams({ page: this.competenciesParams.page + 1 });
    }
  };

  public changeCompetenciesParams = (params: SearchParams) => {
    this.competenciesParams = { ...this.competenciesParams, ...params };
  };

  public getCompetencyOptions = () => {
    this.competencyOptions.loading = true;

    return requestController(() => getCompetencies(withoutEmptyProperties({ ...this.competenciesParams, stage: 'published', limit: 10 })), {
      successCallback: ({ items, totalCount }) => {
        const newCompetencies = items.map((item) => ({ label: item.title, value: item._id }));

        this.competencyOptions.data = this.competenciesParams.page === 1 ? newCompetencies : [...this.competencyOptions.data, ...newCompetencies];
        this.competencyOptions.total = totalCount;
      },
      finallyCallback: () => {
        this.competencyOptions.loading = false;
      },
    }, { showError: false });
  };

}

export const answersStore = new AnswersStore();
