import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTopBarStyles } from './topBar.styles';

export const TopBar: FC = () => {
  const classes = useTopBarStyles();


  return (
    <div className={classes.root}>
      <hr className={classes.line} />
      <div className={classes.content}>
        <Link to="/" className={classes.link_active}>
          Проверка оценочных сессий
        </Link>
      </div>
    </div>
  );
};
