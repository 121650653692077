import ky from 'ky';
import { getCookie } from '../shared/get-cookie';
import { EngineResponse } from './common.types';
import { QUIZER_ERRORS_BY_KEY } from './quizerErrors';

export const baseApi = ky.create({
  prefixUrl: `${process.env.REACT_APP_ASSMNT_BUILDERNEXT_API}/api`,
  hooks: {
    beforeRequest: [
      (request) => {
        request.headers.set('Authorization', getCookie('_utid') || process.env.REACT_APP_LOCAL_TOKEN || '');
      },
    ],
  },
});

export const assessorApi = baseApi.extend({
  prefixUrl: process.env.REACT_APP_ASSESSOR_API,
  hooks: {
    beforeRequest: [
      (request) => {
        request.headers.set('Authorization', getCookie('_utid') || process.env.REACT_APP_LOCAL_TOKEN || '');
      },
    ],
    afterResponse: [
      async(_request, _options, response) => {
        if (!String(response.status).startsWith('2')) {
          throw new Error(QUIZER_ERRORS_BY_KEY[99]);
        }

        const engineResponse: EngineResponse<unknown> = await response.json();

        if (!engineResponse.status) {
          const error = engineResponse.errors[0];
          const quizerError = QUIZER_ERRORS_BY_KEY[error.key] || error.message;

          throw new Error(quizerError);
        }
      },
    ],
  },
});
