import { IRootStore } from '../models/stores/root.store';
import { ConfigStore } from './config.store';
import { RoleStore } from './role.store';
import { UserStore } from './user.auth.store';


export class RootStore implements IRootStore {

  public readonly config: ConfigStore;
  public readonly user: UserStore;
  public readonly role: RoleStore;

  constructor() {
    this.config = new ConfigStore();
    this.user = new UserStore(this);
    this.role = new RoleStore(this);
  }

}
